import {apiUrl} from '@/config'

import axios from 'axios'

import CredentialsService from '@/services/CredentialsService'

const makeUrl = endpoint => `${apiUrl}/${endpoint}`

const makeHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + CredentialsService.getAccessToken(),
    }
}

export default {
    async _get(endpoint, data = {}, headers) {
        return await this.sendRequest('get', endpoint, data, headers)
    },

    async _post(endpoint, data = {}, headers) {
        return await this.sendRequest('post', endpoint, data, headers)
    },

    async _patch(endpoint, data = {}, headers) {
        return await this.sendRequest('patch', endpoint, data, headers)
    },

    async _put(endpoint, data = {}, headers) {
        return await this.sendRequest('put', endpoint, data, headers)
    },

    async _delete(endpoint, data = {}, headers) {
        return await this.sendRequest('delete', endpoint, data, headers)
    },

    async sendRequest(method, endpoint, data = {}, headers) {
        headers = {...headers, ...makeHeaders()}
        return axios({
            method,
            url: makeUrl(endpoint),
            data,
            headers
        })
    }
}
