<template>
  <div v-if="count > 0" class="w-6 h-6 inline-block rounded-full text-center text-white bg-red-500">
    {{ count }}
  </div>
</template>

<script>

export default {

  name: 'v-badge',

  props: {
    count: {
      type: Number,
      default: 0
    },
    // inline: {
    //     type: Boolean,
    //     default: true
    // }
  }

}

</script>
