<template>
  <v-page-loader :loading="isUserFetching"/>

  <v-page-head v-show="!isUserFetching" :title="user.name">
    <template #buttons-r>
      <v-button icon="pencil-outline" @click="showUserModal('edit')"/>
    </template>
  </v-page-head>

  <div v-show="!isUserFetching" class="max-w-screen px-4 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">

    <div class="user-image flex justify-center users-center">
      <mdicon name="account-outline" size="300"/>
    </div>

    <div class="user-info flex flex-col gap-6">
      <h3 class="font-semibold text-xl">Основна інформація</h3>

      <ul class="grid grid-cols-2 gap-6 leading-normal">
        <li>
          <span class="font-semibold">ID</span>
          <p>{{ user.id }}</p>
        </li>

        <li>
          <span class="font-semibold">Роль</span>
          <p>{{ user.role?.name }}</p>
        </li>

        <li class="col-span-full">
          <span class="font-semibold">Email</span>
          <p>{{ user.email }}</p>
        </li>

        <li>
          <span class="font-semibold">Створено</span>
          <v-date :date="user.created_at"/>
        </li>

        <li>
          <span class="font-semibold">Змінено</span>
          <v-date :date="user.updated_at"/>
        </li>
      </ul>

    </div>

    <div v-show="isUserAdmin && isThisUserEmployee"
         class="user-billable-actions p-4 bg-white rounded-[2rem] flex flex-col gap-6">
      <div class="flex flex-wrap justify-between gap-6">
        <h3 class="font-semibold text-xl">Виконані дії</h3>

        <ul class="flex items-center gap-2">
          <li v-for="(interval, i) in billableActionsIntervals" :key="i"
              @click="setBillableActionsInterval(i, interval.interval)"
              class="m8h-interactable p-2 text-center rounded-full cursor-pointer"
              :class="{'font-bold text-primary': i === billableActionsIntervalsIndex}"
          >
            {{ interval.name }}
          </li>
        </ul>
      </div>

      <p class="font-bold text-5xl">{{ getMoney(userBillableActions.total) }}</p>

      <ul class="grid grid-cols-2 gap-6">
        <li v-for="action in userBillableActions.actions" :key="action.action.id" class="flex items-center gap-2">
          <mdicon :name="action.action.icon" size="65"/>
          <p>
            <span>{{ action.action.name }} ({{ action.count }})</span>
            <br>
            <span class="ml-auto font-bold text-2xl">{{ getMoney(action.sum) }}</span>
          </p>
        </li>
      </ul>
    </div>

    <div v-show="isUserAdmin && isThisUserEmployee"
         class="user-available-actions p-4 bg-white rounded-[2rem] flex flex-col gap-6">
      <h3 class="font-semibold text-xl">Доступні дії</h3>

      <ul class="flex flex-wrap justify-items-center gap-6">
        <li v-for="action in actions" :key="action.id" class="w-20 flex items-center gap-6">
          <input type="checkbox"
                 @change="syncActions()"
                 v-model="userAvailableActions"
                 :id="`UserAvailableAction${action.id}`"
                 :value="action.id"
                 class="hidden peer"
          >
          <label :for="`UserAvailableAction${action.id}`"
                 class="block w-full text-center text-gray-200 cursor-pointer peer-checked:text-gray-500 font-semibold hover:text-gray-400">
            <mdicon :name="action.icon" size="65"/>
            <p>{{ action.name }}</p>
          </label>
        </li>
      </ul>
    </div>

  </div>

  <v-modal
      v-show="isUserEditModalOpened"
      @hide="hideUserModal('edit')"
      w="w-full md:w-4/5"
      title="Редагування користувача"
  >
    <UserCreateForm formMode="edit"/>
  </v-modal>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {getMoney} from '@/helper';
import {useAuthStore, useUserStore, useActionStore} from '@/stores';
import UserCreateForm from '@/components/User/forms/UserCreateForm'
import {socket} from "@/socket";

export default {

  name: 'UserDetailsView',

  components: {
    UserCreateForm,
  },

  data() {
    return {
      id: this.$route.params.user,
      state: {
        isCustomer: false,
        isEmployee: false,
      },

      userAvailableActions: [],

      billableActionsIntervals: [
        {interval: 'day', name: 'День'},
        {interval: 'week', name: 'Тиждень'},
        {interval: 'month', name: 'Місяць'},
      ],
      billableActionsIntervalsIndex: 1,

    }
  },

  computed: {
    ...mapState(useAuthStore, {
      isUserAdmin: 'isUserAdmin',
      isUserManager: 'isUserManager',
      isUserCustomer: 'isUserCustomer',
      isUserEmployee: 'isUserEmployee',
    }),

    ...mapState(useUserStore, {
      user: 'getUser',
      isUserFetching: 'isUserFetching',
      isThisUserAdmin: 'isUserAdmin',
      isThisUserManager: 'isUserManager',
      isThisUserCustomer: 'isUserCustomer',
      isThisUserEmployee: 'isUserEmployee',

      userBillableActions: 'getUserBillableActions',
      isUserBillableActionsFetching: 'isUserBillableActionsFetching',

      isUserEditModalOpened: 'isModalEditOpened',
    }),

    ...mapState(useActionStore, {
      actions: 'getActions',
    }),
  },

  methods: {
    ...mapActions(useUserStore, [
      'fetchUser',
      'syncAvailableActions',
      'fetchBillableActions',
      'showUserModal',
      'hideUserModal',
    ]),

    ...mapActions(useActionStore, [
      'fetchActions',
    ]),

    getMoney,

    syncActions() {
      this.syncAvailableActions(this.id, this.userAvailableActions)
    },

    setBillableActionsInterval(index, interval) {
      this.billableActionsIntervalsIndex = index
      this.fetchBillableActions(this.id, interval)
    }
  },

  watch: {
    user(newUser) {
      this.userAvailableActions = newUser.available_actions.map(a => a.action_id)
    },
    isThisUserEmployee(newData) {
      if(newData) {
        this.fetchActions()
        this.fetchBillableActions(this.id, 'week')
      }
    }
  },

  async created() {
    this.fetchUser(this.id)

    // this.fetchActions()
    // this.fetchBillableActions(this.id, 'week')

    socket.on('orderItemAction', event => {
      if (this.id === event.user_id) {
        this.fetchBillableActions(this.id, this.billableActionsIntervals[this.billableActionsIntervalsIndex])
      }
    })
  },

}

</script>
