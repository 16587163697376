import {defineStore} from 'pinia'

import AuthService from '@/services/AuthService'
import CredentialsService from '@/services/CredentialsService'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        auth: CredentialsService.getAccessToken()?.length > 0,
        user: CredentialsService.getUser(),
        errors: [],
    }),

    getters: {
        getUser(state) {
            return state.user
        },

        isUserAuth(state) {
            return state.auth
        },

        isUserAdmin(state) {
            return state.auth ? this.user.role_id === 1 : false
        },

        isUserManager(state) {
            return state.auth ? this.user.role_id === 2 : false
        },

        isUserCustomer(state) {
            return state.auth ? this.user.role_id === 3 : false
        },

        isUserEmployee(state) {
            return state.auth ? this.user.role_id === 4 : false
        },
    },

    actions: {
        async login(values) {
            const res = await AuthService.login(values)

            try {
                this.$patch({
                    errors: []
                })

                if (res.data.errors?.length > 0) {
                    this.$patch({
                        errors: res.data.errors
                    })

                    return 0
                }

                if (res.data.data.token === undefined || res.data.data.user === undefined) return 0

                await CredentialsService.setAccessToken(res.data.data.token)
                await CredentialsService.setUser(res.data.data.user)

                this.$patch({
                    auth: true,
                    user: res.data.data.user,
                })

                window.location.href = '/orders'
            } catch (e) {
                // console.log(e)
            }

        },

        async logout() {
            await AuthService.logout()
            await CredentialsService.clear()

            this.$patch({
                auth: false,
                user: undefined
            })

            window.location.href = '/auth'
        },

        async fetchCurrentUser() {
            if (this.auth) {
                const res = await AuthService.current()

                try {
                    this.user = res.data
                } catch (e) {
                    console.log(e)
                }
            }
        },
    },
})
