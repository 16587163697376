import AuthView from "@/views/AuthView.vue";

export const authRoutes = {
    path: '/auth',
    component: AuthView,
    name: 'Вхід',
    icon: 'home-outline',
    badge: 0,
    meta: {
        displayHeader: false,
        mainMenuLink: false,
    }
}
