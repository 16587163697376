<template>
  <div class="w-full">
    <label v-if="label" :for="id" class="w-full mb-2 flex justify-left space-x-2">
      <mdicon v-if="icon" :name="icon"/>
      <span v-if="label">{{ label }}</span>
    </label>

    <textarea
        class="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
        @change="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :value="modelValue"
        :placeholder="label"
    ></textarea>
  </div>
</template>

<script>

export default {

  name: 'v-text',

  emits: ['update:modelValue'],

  props: {
    label: String,
    icon: String,
    id: String,
    modelValue: {type: String, default: ''},
    required: {type: Boolean, default: true},

  }

}

</script>
