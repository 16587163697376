<template>
  <div class="relative">
    <div v-show="sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
      <v-loader class="my-40" :loading="sending"/>
    </div>

    <form>
      <ul class="grid grid-cols-2 gap-6">
        <li>
          <ul class="grid grid-cols-2 gap-4">
            <li class="col-span-full">
              <v-input v-model="data.name" id="ItemModalName" label="Назва" type="text" :error="errors.name"/>
            </li>
            <li>
              <v-select v-model="data.type_id" id="ItemModalType" label="Тип товару"
                        :options="types" :selected="data.type_id" :error="errors.type_id"
              />
            </li>
            <li>
              <v-input-number v-model="data.price" id="ItemModalPrice" label="Ціна" :error="errors.price"/>
            </li>
            <li class="col-span-full">
              <v-text v-model="data.desc" id="ItemModalDesc" label="Опис" :error="errors.desc"/>
            </li>

          </ul>

          <hr class="my-6">

          <h3 class="font-semibold">Вартість операцій</h3>

          <ul class="w-full mt-4 grid grid-cols-3 text-sm gap-4">
            <li v-for="action in actions" :key="action.id">
              <v-input-number v-model="data.actionPrices[action.id]"
                              :label="action.name" :icon="action.icon"
                              step="0.5" min="0"
              />
            </li>
          </ul>

        </li>

        <li>
          <v-loader v-show="isModsFetching"/>

          <div v-show="!isModsFetching">
            <h3 class="font-semibold">Модифікації</h3>

            <ul class="product-mods mt-4 w-full grid grid-cols-2 text-sm">
              <li v-for="category in mods" :key="category.id">
                <div class="w-full mb-3">
                  <span v-if="category.name">
                    {{ category.name }}
                    <span v-if="category.desc" class="text-gray-400">{{ category.desc }}</span>
                  </span>

                  <ul class="w-full flex flex-wrap justify-start gap-1">
                    <li>
                      <div class="p-1 w-6 text-center text-gray-400 cursor-pointer font-semibold hover:text-red-500"
                           @click="clearMod(category.slug)"
                      >
                        &#10005;
                      </div>
                    </li>
                    <li v-for="mod in category.mods" :key="mod.id">
                      <input type="radio"
                             v-model="data.mods[category.slug]"
                             :id="`ProductCreateMod${mod.id}`"
                             :name="category.slug"
                             :value="mod.id"
                             :checked="Object.values(data.mods).includes(mod.id)"
                             class="hidden peer"
                      >
                      <label :for="`ProductCreateMod${mod.id}`"
                             class="block p-1 min-w-[3rem] text-center text-gray-400 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white font-semibold hover:text-gray-600 hover:bg-gray-100">
                        {{ mod.name }}
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div v-show="errors.mods" class="text-red-500">{{ errors.mods }}</div>
        </li>

      </ul>

      <div class="modal-footer text-right">
        <button @click="reset()" class="mt-5 p-4 rounded-lg modal-close" type="button">Скасувати</button>

        <button @click="addItem(data)" v-if="formMode === 'create'"
                class="bg-primary text-white mt-5 p-4 rounded-lg" type="button">Створити
        </button>

        <button @click="updateItem(data)" v-if="formMode === 'edit'"
                class="bg-primary text-white mt-5 p-4 rounded-lg" type="button">Зберегти
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";

import {useItemStore} from "@/stores/ItemStore";

export default {

  name: 'ItemCreateForm',

  props: {
    formMode: {type: String, default: 'create'},
  },

  data() {
    return {
      data: {
        name: '',
        type_id: -1,
        price: 0,
        desc: '',

        mods: {
          width: 22,
          height: 28,
        },

        actionPrices: {},
      }
    }
  },

  computed: {
    ...mapState(useItemStore, {
      item: 'getItem',
      types: 'getTypes',
      mods: 'getMods',
      actions: 'getActions',
      isTypesFetching: 'isTypesFetching',
      isModsFetching: 'isModsFetching',
      isActionsFetching: 'isActionsFetching',
      errors: 'getErrors',
      sending: 'isSendingData',
    }),
  },

  methods: {
    ...mapActions(useItemStore, [
      'fetchItemOptions',
      'addItem',
      'updateItem',
    ]),

    clearMod(slug) {
      delete this.data.mods[slug]
    },

    reset() {
      this.data.name = this.item.name
      this.data.type_id = this.item.type_id
      this.data.price = this.item.price
      this.data.desc = this.item.desc
    },
  },

  watch: {
    item(newItem) {
      if (Object.keys(newItem).length > 0) {
        this.data.name = newItem.name
        this.data.type_id = newItem.type_id
        this.data.price = newItem.price
        this.data.desc = newItem.desc

        // this.data.mods = newItem._mods

        newItem._mods.forEach(m => {
          this.data.mods[m.mod.category.slug] = m.mod_id
        })

        newItem.action_prices.forEach(a => {
          this.data.actionPrices[a.action_id] = a.price
        })
      }
    }
  },

  created() {
    this.fetchItemOptions()
  }
}

</script>
