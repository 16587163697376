import {defineStore} from 'pinia'

import ActionService from '@/services/ActionService'

export const useActionStore = defineStore('action', {

    state: () => ({
        actions: [],

        loading: {
            actions: false,
            sending: false,
        },

        modal: {
            create: false,
            edit: false,
        },

        errors: [],
    }),

    getters: {
        getActions(state) {
            return state.actions;
        },

        isActionsFetching(state) {
            return state.loading.actions
        },


        getErrors(state) {
            return state.errors
        },


        isSendingData(state) {
            return state.loading.sending
        },


        isModalCreateOpened(state) {
            return state.modal.create
        },

        isModalEditOpened(state) {
            return state.modal.edit
        },
    },

    actions: {
        async fetchActions() {
            this.loading.actions = true

            const res = await ActionService.fetchActions()

            try {
                this.actions = res.data.data
            } catch (e) {
                this.actions = []
            }

            this.loading.actions = false
        },

        //

        showItemModal(name) {
            this.modal[name] = true
        },

        hideItemModal(name) {
            this.modal[name] = false
        },

        toggleItemModal(name) {
            this.modal[name] = !this.modal[name]
        },
    },

})
