<template>
  <div
      @click.stop="$emit('hide')"
      class="modal w-full h-full fixed top-0 left-0 fade z-10 backdrop-blur-md overflow-y-auto cursor-pointer"
      :class="bg"
      role="dialog"
      aria-modal="true"
  >
    <div @click.stop
         class="modal-frame w-60 h-full absolute right-0 p-4 bg-white text-gray-800 shadow-xl overflow-hidden transform transition-all cursor-default"
    >

      <div class="modal-header flex justify-between mb-5">
        <h2 class="modal-title font-semibold text-xl">{{ title }}</h2>
        <mdicon @click="$emit('hide')" name="close" class="cursor-pointer"/>
      </div>

      <div class="modal-body">
        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  name: 'v-modal-menu',

  props: {
    title: {type: String, default: 'Menu'},
    bg: {type: String, default: 'bg-white/20'},
  },


}

</script>
