import {defineStore} from 'pinia'

import ItemService from '@/services/ItemService'

// import {createItemRequest, updateItemRequest} from '@/requests/ItemRequests'

export const useItemStore = defineStore('item', {

    state: () => ({
        items: [],
        item: {},

        types: [],
        mods: [],
        actions: [],

        loading: {
            items: false,
            item: false,
            types: false,
            mods: false,
            actions: false,
            sending: false,
        },

        modal: {
            create: false,
            edit: false,
        },

        errors: [],
    }),

    getters: {
        getItems(state) {
            return state.items;
        },

        isItemsFetching(state) {
            return state.loading.items
        },


        getItem(state) {
            return state.item;
        },

        isItemFetching(state) {
            return state.loading.item
        },

        getTypes(state) {
            return state.types;
        },

        isTypesFetching(state) {
            return state.loading.types
        },


        getMods(state) {
            return state.mods;
        },

        isModsFetching(state) {
            return state.loading.mods
        },


        getActions(state) {
            return state.actions;
        },

        isActionsFetching(state) {
            return state.loading.actions
        },


        getErrors(state) {
            return state.errors
        },


        isSendingData(state) {
            return state.loading.sending
        },


        isModalCreateOpened(state) {
            return state.modal.create
        },

        isModalEditOpened(state) {
            return state.modal.edit
        },
    },

    actions: {
        async fetchItems(filter) {
            this.loading.items = true

            this.item = {}
            const res = await ItemService.fetchItems(filter)

            try {
                this.items = res.data.data
            } catch (e) {
                this.items = []
            }

            this.loading.items = false
        },

        async fetchItem(id) {
            this.loading.item = true

            const res = await ItemService.fetchItem(id)

            try {
                this.item = res.data.data
            } catch (e) {
                this.item = {}
            }

            this.loading.item = false
        },

        async fetchItemDirect(id) {
            const res = await ItemService.fetchItem(id)
            return res.data.data
        },

        async fetchItemOptions() {
            this.loading.types = true
            this.loading.mods = true
            this.loading.actions = true

            const res = await ItemService.fetchOptions()

            try {
                this.types = res.data.data.types
                this.mods = res.data.data.mods
                this.actions = res.data.data.actions
            } catch (e) {
                this.types = []
                this.mods = []
                this.actions = []
            }

            this.loading.types = false
            this.loading.mods = false
            this.loading.actions = false
        },

        async addItem(item) {
            this.loading.sending = true

            // this.errors = createItemRequest(item)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await ItemService.createItem(item)

            try {
                if (res.data.status) {
                    this.hideItemModal('create')
                    await this.fetchItems()
                }
            } catch (e) {
                //
            }

            this.loading.sending = false
        },

        async updateItem(data) {
            this.loading.sending = true

            // this.errors = updateItemRequest(data)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await ItemService.updateItem(this.item.id, data)

            try {
                if (res.data.status) {
                    this.hideItemModal('edit')
                    await this.fetchItem(this.item.id)
                }
            } catch (e) {
                //
            }

            this.loading.sending = false
        },

        showItemModal(name) {
            this.modal[name] = true
        },

        hideItemModal(name) {
            this.modal[name] = false
        },

        toggleItemModal(name) {
            this.modal[name] = !this.modal[name]
        },
    },

})
