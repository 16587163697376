<template>
  <div
      class="w-full h-full fixed top-0 left-0 fade z-10 backdrop-blur-md overflow-y-auto"
      :class="bg"
      role="dialog"
      aria-modal="true"
  >
    <div @click.stop :class="w"
         class="modal-frame m-auto my-60 px-4 py-12 bg-white text-gray-800 rounded-xl shadow-xl overflow-hidden transform transition-all cursor-default">

      <div class="modal-body">

        <div class="text-center">
          <mdicon :name="icon" :size="size" :class="color"/>
        </div>

        <div :class="{'text-center': center}">
          <h2 class="mt-6 modal-title font-semibold text-xl">{{ title }}</h2>
          <p class="mt-6">{{ text }}</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {

  name: 'v-screen-lock',

  props: {
    title: {type: String, default: ''},
    icon: {type: String, default: 'alert-circle-outline'},
    size: {type: Number, default: 100},
    color: {type: String, default: ''},
    text: {type: String, default: ''},
    center: {type: Boolean, default: true},

    bg: {type: String, default: 'bg-white/20'},

    w: {type: String, default: 'w-1/4'},
  },

}

</script>
