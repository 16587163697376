<template>
  <div class="group relative">

    <div @click="isOpen = !isOpen" class="dropdown flex justify-between space-x-2 cursor-pointer">
      <div class="p-5 relative">
        <mdicon name="bell-outline"/>

        <div v-show="notifications.length > 0"
             class="w-4 h-4 absolute top-1/4 left-half rounded-full text-center text-xs text-white bg-red-500">
          {{ notifications.length }}
        </div>
      </div>
    </div>

    <transition name="fade" appear>
      <ul v-show="isOpen"
          class="dropdown-menu absolute top-full right-0 w-60 rounded-md shadow-lg bg-white overflow-hidden z-10">

        <li v-for="item in notifications" :key="item.id" class="hover:bg-gray-50">
          <a class="p-2 leading-none flex justify-left space-x-2 cursor-pointer">
            <span>{{ item.text }}</span>
          </a>
        </li>

      </ul>
    </transition>

  </div>
</template>

<script>

export default {
  name: 'NavNotificationComponent',

  data() {
    return {
      isOpen: false,

      notifications: [
        {id: 1, text: 'Username is logged in'},
        {id: 2, text: 'Lorem ipsum dolor sit amet'}
      ]
    }
  }
}
</script>
