import {createRouter, createWebHistory} from 'vue-router'

import HomeView from '@/views/HomeView.vue'

import {authRoutes, userRoutes, itemRoutes, orderRoutes} from './routes';

const routes = [
    {
        path: '/',
        component: HomeView,
        name: 'Головна',
        icon: 'home-outline',
        meta: {
            displayHeader: true,
            mainMenuLink: true,
        },
    },

    ...[authRoutes],
    ...[userRoutes],
    ...[itemRoutes],
    ...[orderRoutes],

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
