<template>
    <div class="w-full mb-3">
        <div class="image-upload-preview mb-3" v-if="previewImage?.length > 0">
            <img :src="previewImage" alt="" class="rounded-lg border">
        </div>

        <label v-if="label" :for="id" class="w-full flex justify-left space-x-2">
            <mdicon v-if="icon" :name="icon"/>
            <span v-if="label">{{ label }}</span>
        </label>

        <input
            @change="preview($event)"
            :id="id" type="file"
            :name="name"
            accept="image/*"
            class="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
            :placeholder="label"
            :required="required"
        />

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>

    </div>
</template>

<script>
export default {

    name: 'v-input-image',

    emits: [ 'update:modelValue' ],

    props: {
        label: String,
        icon: String,
        id: String,
        name: { type: String, required: true, default: 'image' },
        modelValue: { type: [ String, Number ], default: '' },
        required: { type: Boolean, default: true },
        error: { type: String, default: '' },
        formMode: { type: String, default: 'create' },
        orientation: { type: String, default: 'horizontal' }, // vertical, horizontal
        image: String,
    },

    methods: {
        preview( event ) {
            const reader = new FileReader();

            reader.readAsDataURL( event.target.files[0] );
            reader.onload = e => {
                this.previewImage = e.target.result;
            };

            this.$emit( 'update:modelValue', event.target.files[0] )
        }
    },

    data() {
        return {
            previewImage: (this.orientation == 'horizontal') ? 'https://via.placeholder.com/1800x1200' : 'https://via.placeholder.com/1200x1800'
        }
    },

    mounted() {
        this.previewImage = this.image
    },

}

</script>
