<template>
  <nav aria-label="Breadcrumb">
    <ul class="flex justify-left items-center space-x-4 text-sm text-gray-500">
      <li>
        <router-link to="/" class="m8h-interactable p-1 font-bold hover:text-primary">
          Головна
        </router-link>
      </li>

      <li v-for="(item, i) in items" :key="i" class="flex justify-left items-center space-x-4">
        <span>/</span>

        <div v-if="isLast(i)" class="m8h-interactable p-1 cursor-default">
          {{ dynamic ?? item.name }}
        </div>

        <router-link v-else :to="item.path" class="m8h-interactable p-1 hover:text-primary">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'v-breadcrumbs',

  props: {
    dynamic: {type: String, default: ''}
  },

  methods: {
    isLast(index) {
      return index === this.items.length - 1
    },
  },

  computed: {
    items() {
      return this.$route.matched
    }
  },
}
</script>
