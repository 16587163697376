<template>
  <v-page-loader :loading="isOrdersFetching"/>

  <v-page-head :title="$route.matched[0].name">
    <template #buttons-r>
      <v-button icon="reload" @click="fetchOrders()"/>
      <v-button icon="plus" @click="showOrderModal('create')"/>
    </template>
  </v-page-head>

  <div class="max-w-screen p-6 mx-auto grid grid-cols-1 gap-6">
    <OrderItem
        v-for="order in orders"
        :key="order.id"
        :order="order"
        :edit="order.id === editId"
    />
  </div>

  <v-modal v-show="isOrderCreateModalOpened"
           @hide="hideOrderModal('create')"
           w="w-full md:w-11/12"
           title="Нове замовлення"
  >
    <OrderCreateForm/>
  </v-modal>

</template>

<script>
import {mapActions, mapState} from 'pinia'
import {socket} from '@/socket'
import {notification} from "@/sounds"
import OrderItem from '@/components/Order/OrderItem'
import OrderCreateForm from '@/components/Order/forms/OrderCreateForm'
import {useOrderStore} from '@/stores'

export default {
  name: 'OrdersView',

  components: {
    OrderItem,
    OrderCreateForm,
  },

  data() {
    return {
      editId: 0
    }
  },

  computed: {
    ...mapState(useOrderStore, {
      orders: 'getOrders',
      customers: 'getCustomers',
      mods: 'getMods',
      materials: 'getMaterials',
      isOrdersFetching: 'isOrdersFetching',

      isOrderCreateModalOpened: 'isModalCreateOpened',
    })
  },

  methods: {
    ...mapActions(useOrderStore, [
      'fetchOrders',
      'fetchOrdersLight',
      'fetchOptions',
      'showOrderModal',
      'hideOrderModal',
    ]),


  },

  async created() {

    socket.on('orderCreated', () => {
      new Audio(notification).play()
      this.fetchOrdersLight()
    })

    socket.on('order:edit', event => {
      this.editId = parseInt(event.flags.edit) ? parseInt(event.order_id) : 0
    })

    await this.fetchOrders()
  },
}

</script>
