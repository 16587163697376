export default {
    setUser( user ) {
        localStorage.setItem( 'user', JSON.stringify( user ) )
    },

    getUser() {
        return JSON.parse( localStorage.getItem( 'user' ) ) ?? undefined
    },


    setAccessToken( token ) {
        localStorage.setItem( 'accessToken', token )
    },

    getAccessToken() {
        return localStorage.getItem( 'accessToken' )
    },


    clear() {
        localStorage.removeItem( 'accessToken' )
        localStorage.removeItem( 'user' )
    },

}
