<template v-show="user">
  <v-page-loader :loading="isOrderFetching"/>

  <v-page-head :title="`Замовлення #${order.id}`" :class="{'text-flow': isOrderFetching}">
    <template #buttons-r>
      <v-button v-show="isUserAdmin" icon="pencil-outline" @click="showOrderModal('edit')"/>
    </template>
  </v-page-head>

  <div :class="{'text-flow': isOrderFetching}" class="max-w-screen px-4 mx-auto">

    <div v-show="isUserAdmin" class="order-info text-lg">
      <ul class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4">
        <li>
          <span class="font-semibold">Замовник</span>
          <p>{{ order?.user?.name }}</p>
        </li>

        <li>
          <span class="font-semibold">Сума</span>
          <p class="font-mono">{{ getMoney(order.price_total) }}</p>
        </li>

        <li>
          <span class="font-semibold">Створено</span>
          <v-date :date="order.created_at" :diff="true"/>
        </li>

        <li>
          <span class="font-semibold">Змінено</span>
          <v-date :date="order.updated_at" :diff="true"/>
        </li>

        <li v-show="order.desc" class="col-span-full">
          <span class="font-semibold">Опис</span>
          <p>{{ order.desc }}</p>
        </li>
      </ul>

      <hr class="my-12">

    </div>

    <div class="order-list">
      <div class="bg-white rounded-[2rem] overflow-hidden">

        <ul class="w-full">
          <li v-for="(item, i) in order.items" :key="item.id" class="even:bg-gray-50">
            <div class="w-full p-4 flex flex-wrap justify-between text-2xl relative">

              <div v-show="flags.item.edit && flags.item.id === item.id" class="w-full h-full flex flex-row justify-center items-center gap-4 absolute top-0 left-0 backdrop-blur-md">
                  <mdicon name="pencil-outline" size="40"/>
                  <span>Редагується...</span>
              </div>

              <div class="pr-4 shrink-1 text-2xl font-bold">
                {{ i + 1 }}
              </div>

              <div class="grow grid grid-cols-1 gap-4">

                <ul class="grid grid-cols-2 md:flex md:justify-between gap-4">
                  <li class="col-span-full grow text-black font-semibold">
                    {{ `${item.item.type.short} ${item.item.name} ${item.name}` }}
                  </li>

                  <li class="shrink-1">
                    {{ item.material.name }}
                  </li>

                  <li v-show="isUserAdmin" class="text-right">
                    {{ getMoney(item.price) }}
                  </li>

                  <li v-show="isUserAdmin">
                    <v-button icon="pencil-outline" size="sm" @click="handleItemEditButton(item.id)"/>
                  </li>
                </ul>

                <p>
                  {{ item.desc }}
                </p>

                <div class="flex flex-col md:flex-row justify-center md:justify-between gap-4">
                  <ul class="flex gap-4 text-base">
                    <li v-for="mod in item.mods" :key="mod.mod_id">
                      <div class="w-full mb-3">{{ mod.mod.category.name }}</div>
                      <div class="w-full mb-3">
                      <span
                          class="p-1 text-center text-white font-semibold bg-primary border border-gray-200 rounded-lg cursor-pointer">
                        {{ mod.mod.name }}
                      </span>
                      </div>
                    </li>
                  </ul>

                  <ul class="flex justify-center shrink-1">
                    <li v-show="isActionAvailable(action.id) || isUserAdmin" v-for="action in actions" :key="action.id"
                        :title="`${action.name}`"
                        class="p-4 text-gray-200 cursor-pointer hover:text-gray-300"
                        :class="{'text-primary hover:text-primary-light': isActionChecked(action.id, item.actions)}"
                        @click="makeAction(item.id, action.id, item.actions)"
                    >
                      <mdicon :name="action.icon"/>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
          </li>
        </ul>

      </div>

    </div>
  </div>

  <v-modal
      v-show="isOrderEditModalOpened"
      @hide="hideOrderModal('edit')"
      w="w-full xl:w-4/5"
      title="Редагування замовлення"
  >
    <OrderCreateForm formMode="edit"/>
  </v-modal>

  <v-modal
      v-show="isItemEditModalOpened"
      @hide="hideOrderItemModal('edit')"
      w="w-full xl:w-4/5"
      title="Редагування пункту замовлення"
  >
    <OrderItemCreateForm formMode="edit"/>
  </v-modal>

  <v-screen-lock v-show="flags.order.edit" text="Замовлення редагується" color="amber-400"/>

</template>

<script>
import {mapActions, mapState} from "pinia"
import {getMoney} from '@/helper'
import {pop} from '@/sounds'
import {socket} from '@/socket'
import OrderCreateForm from '@/components/Order/forms/OrderCreateForm'
import OrderItemCreateForm from '@/components/Order/forms/OrderItemCreateForm'
import {useAuthStore, useOrderStore, useItemStore} from "@/stores";
import VScreenLock from "@/components/_ui/ScreenLock.vue";

export default {

  name: 'OrderDetailsView',

  components: {
    VScreenLock,
    OrderCreateForm,
    OrderItemCreateForm,
  },

  data() {
    return {
      id: this.$route.params.order,
      item_id: null,

      flags: {
        order: {
          edit: false,
        },
        item: {
          edit: false,
          id: 0
        },
      }
    }
  },

  computed: {
    ...mapState(useAuthStore, {
      user: 'getUser',
      isUserAdmin: 'isUserAdmin',
    }),

    ...mapState(useItemStore, {
      actions: 'getActions',
    }),

    ...mapState(useOrderStore, {
      order: 'getOrder',
      isOrderFetching: 'isOrderFetching',

      item: 'getOrderItem',
      isItemFetching: 'isOrderItemFetching',

      isOrderEditModalOpened: 'isModalEditOpened',
      isItemEditModalOpened: 'isModalItemEditOpened',
    }),

  },

  methods: {
    ...mapActions(useAuthStore, [
      'fetchCurrentUser',
    ]),

    ...mapActions(useItemStore, [
      'fetchItemOptions',
    ]),

    ...mapActions(useOrderStore, [
      'fetchOrder',
      'fetchOrderItem',
      'fetchOrderItemActions',
      'fetchOrderOptions',
      'showOrderModal',
      'hideOrderModal',
      'showOrderItemModal',
      'hideOrderItemModal',
      'makeItemAction',
      'setOrderFlag',
      'setOrderItemFlag',
    ]),

    getMoney,

    makeAction(itemId, actionId, actions) {
      if (!this.isActionChecked(actionId, actions)) this.makeItemAction(itemId, actionId)
    },

    isActionAvailable(actionId) {
      return this.user ? this.user.available_actions.map(a => a.action_id).includes(actionId) : false
    },

    isActionChecked(actionId, itemActions) {
      return itemActions.map(a => a.action_id).includes(actionId)
    },

    handleItemEditButton(id) {
      this.item_id = id

      this.showOrderItemModal('edit')
      this.fetchOrderItem(id)
    },

  },

  watch: {
    isOrderEditModalOpened(newState) {
      this.setOrderFlag({'edit': newState})
    },
    isItemEditModalOpened(newState) {
      this.setOrderItemFlag(this.item_id, {'edit': newState})
    },
  },

  mounted() {
    this.fetchOrder(this.id)
    this.fetchItemOptions()
    this.fetchOrderOptions()

    socket.on('orderItemAction', event => {
      if (this.id === event.order_id) {
        new Audio(pop).play()
        this.fetchOrderItemActions()
      }
    })

    socket.on('syncAvailableActions', event => {
      if (this.user.id === parseInt(event.user_id)) {
        this.fetchCurrentUser()
      }
    })

    socket.on('order:edit', event => {
      if (parseInt(this.id) === parseInt(event.order_id)
          && parseInt(this.user.id) !== parseInt(event.user_id)
      ) {
        this.flags.order.edit = parseInt(event.flags.edit)
      }
    })

    socket.on('order:item:edit', event => {
      if (parseInt(this.id) === parseInt(event.order_id)
          // && parseInt(this.item.id) === parseInt(event.item_id)
          && parseInt(this.user.id) !== parseInt(event.user_id)
      ) {
        this.flags.item.edit = parseInt(event.flags.edit)
        this.flags.item.id = parseInt(event.item_id)
      }
    })

    socket.on('orderUpdated', event => {
      if (parseInt(this.id) === parseInt(event.order_id)
          && parseInt(this.user.id) !== parseInt(event.user_id)
      ) {
        this.fetchOrder(this.id)
      }
    })
  },

}

</script>
