<template>
  <div class="w-10 h-10 py-3 m-3 rounded-full font-bold text-center text-white bg-primary">
    {{ acronym() }}
  </div>
</template>

<script>
import {getAcronym} from '@/helper'

export default {
  name: 'LiteralAvatar',

  props: {
    name: {type: String, default: ''},
  },

  methods: {
    acronym() {
      return getAcronym(this.name)
    }
  }

}
</script>
