<template>
  <div class="leading-none">
    <span>{{ getDate() }}</span>
    <p v-show="diff" class="text-gray-400 text-sm">{{ getRelativeDate() }}</p>
  </div>
</template>

<script>

export default {

  name: 'v-date',

  props: {
    date: {type: Date, default: 0},
    format: {type: String, default: 'DD.MM.YYYY HH:mm'},
    diff: {type: Boolean, default: false}
  },

  methods: {
    getDate() {
      return this.$moment(this.date).format(this.format)
    },

    getRelativeDate() {
      return this.$moment(this.date).fromNow()
    },
  }

}

</script>
