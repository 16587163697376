import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    fetchActions() {
        return ApiRequestProvider._get('actions')
    },

    makeAction(order_id, item_id, action_id) {
        return ApiRequestProvider._post(`orders/action`, {
            order_id,
            item_id,
            action_id,
        })
    },

}
