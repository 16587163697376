<template>
  <div class="max-w-screen p-4 mx-auto flex flex-col text-gray-600">

    <v-breadcrumbs :dynamic="pageTitle"/>

    <div class="flex justify-between gap-4">

      <router-link :to="first.path" class="m8h-interactable p-1 font-bold hover:text-primary">
        <mdicon name="chevron-left" size="40"/>
      </router-link>

      <h1 class="font-bold mr-4 text-2xl leading-loose">
        {{ current.name ?? pageTitle }}
      </h1>

      <div class="buttons mr-auto flex justify-between space-x-2">
        <slot name="ipp"></slot>
        <slot name="groupBy"></slot>
        <slot name="buttons-l"></slot>
      </div>

      <div class="buttons ml-auto flex justify-between space-x-2">
        <slot name="buttons-r"></slot>
      </div>

    </div>

  </div>
</template>

<script>
import {appName} from '@/config'

export default {
  name: 'v-page-head',

  props: ['title'],

  data() {
    return {
      pageTitle: ''
    }
  },

  watch: {
    title(newTitle) {
      this.pageTitle = newTitle ?? this.title

      document.title = `${newTitle} - ${appName}`
    }
  },

  computed: {
    current() {
      return this.$route.matched.slice(-1)
    },
    first() {
      return this.$route.matched[0]
    },
  },

  created() {
    this.pageTitle = this.title
    document.title = `${this.title} - ${appName}`
  }

}

</script>
