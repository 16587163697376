<template>
  <div class="relative">
    <div v-show="sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
      <v-loader class="my-40" :loading="sending"/>
    </div>

    <div class="flex flex-col gap-y-4">


      <hr v-show="formMode === 'create'" class="my-6">

      <ul class="w-full flex flex-row justify-between items-start gap-2">
        <li class="grow">
          <ul class="w-full flex justify-between items-center gap-2">
            <li class="flex-grow">
              <v-select v-model.number="item.item_id"
                        custom-class="p-2"
                        :options="items"
                        :selected="item.item_id"
                        @change="applyItemData(item.item_id)"
              />
            </li>

            <li class="w-40">
              <v-select-grouped v-model.number="item.material_id"
                                :options="materials"
                                :selected="item.material_id"
              />
            </li>

            <li class="flex-grow">
              <v-input v-model="item.name" placeholder="Назва"/>
            </li>

            <li class="w-16">
              <v-input-number v-model.number="item.amount" placeholder="Кількість" min="1"/>
            </li>

            <li class="w-24">
              <v-input-number v-model.number="item.price" placeholder="Ціна" min="1"/>
            </li>
          </ul>

          <p class="w-full mt-2">
            <v-text v-model="item.desc"/>
          </p>

          <div class="mt-2 flex flex-col">
            <div class="w-full flex justify-center items-center gap-2">
              <span class="font-semibold">Модифікації</span>
            </div>

            <ul class="item-mods mt-4 w-full grid grid-cols-6 gap-1 text-sm">
              <li v-for="category in mods" :key="`${category.id}`">
                <div class="w-full mb-3">
                  <span v-if="category.name">
                    {{ category.name }}
                    <span v-if="category.desc" class="text-gray-400">{{ category.desc }}</span>
                  </span>

                  <ul class="w-full flex flex-wrap justify-start gap-0.5 leading-none">
                    <li>
                      <div
                          class=" w-6 text-center text-gray-400 cursor-pointer font-semibold hover:text-red-500"
                          @click="clearMod(category.slug)"
                      >
                        &#10005;
                      </div>
                    </li>
                    <li v-for="mod in category.mods"
                        :key="`${category.id}_${mod.id}`"
                    >
                      <input type="radio"
                             v-model="data.mods[category.slug]"
                             :id="`${category.id}_${mod.id}`"
                             :name="category.slug"
                             :value="mod.id"
                             :checked="Object.keys(data.mods).includes(mod.id)"
                             class="hidden peer"
                      >
                      <label :for="`${category.id}_${mod.id}`"
                             class="block px-1 min-w-[3rem] text-center text-gray-400 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white font-semibold hover:text-gray-600 hover:bg-gray-100">
                        {{ mod.name }}
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>

      </ul>

    </div>

  </div>

  <div class="modal-footer text-right">
    <button @click="createOrderItem(data)"
            v-if="formMode === 'create'"
            class="bg-primary text-white mt-5 p-4 rounded-lg"
            type="button">Створити
    </button>

    <button @click="updateOrderItem(data)"
            v-if="formMode === 'edit'"
            class="bg-primary text-white mt-5 p-4 rounded-lg"
            type="button">Зберегти
    </button>
  </div>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {useItemStore, useOrderStore} from "@/stores";

export default {

  name: 'OrderItemCreateForm',

  props: {
    formMode: {type: String, default: 'create'},
  },

  data() {
    return {
      data: {
        id: null,
        order_id: null,
        item_id: null,
        material_id: null,
        name: '',
        amount: null,
        price: null,
        desc: '',
        mods: {},
      },
    }
  },

  computed: {
    ...mapState(useItemStore, {
      items: 'getItems',
      types: 'getTypes',
      mods: 'getMods',
      actions: 'getActions',
    }),

    ...mapState(useOrderStore, {
      item: 'getOrderItem',
      materials: 'getMaterials',
      isTypesFetching: 'isTypesFetching',
      isModsFetching: 'isModsFetching',
      isActionsFetching: 'isActionsFetching',
      errors: 'getErrors',
      sending: 'isSendingData',
    }),
  },

  methods: {
    ...mapActions(useItemStore, [
      'fetchItems',
      'fetchItemDirect',
      'fetchItemOptions',
    ]),

    ...mapActions(useOrderStore, [
      'fetchItem',
      'createOrderItem',
      'updateOrderItem',
    ]),

    async applyItemData(id) {
      if (id === -1) {
        this.data.mods = {}
      } else {
        const item = await this.fetchItemDirect(id)

        this.data.price = item.price

        this.data.mods = {}

        item._mods.forEach(mod => {
          this.data.mods[mod.mod.category.slug] = mod.mod_id
        })
      }
    },

    clearMod(slug) {
      delete this.data.mods[slug]
    },

  },

  watch: {
    item(newItem) {
      if (newItem.mods) {
        const mods = {}
        newItem.mods.forEach(mod => mods[mod.mod.category.slug] = mod.mod_id)
        newItem.mods = mods
        this.data = newItem
      }
    }
  },

  // created() {
  //   this.fetchItems()
  //   this.fetchItemOptions()
  //   this.fetchOrderOptions()
  // }
}

</script>
