<template>
  <div class="hidden md:block">
    <ul class="flex justify-between">
      <li v-for="route in routes" :key="route.path">
        <router-link :to="route.path" class="py-5 px-4 flex justify-between items-center space-x-2">
          <mdicon v-if="route.icon" :name="route.icon" class="text-primary"/>
          <span class="hidden lg:inline">{{ route.name }}</span>
          <div v-if="route.badge > 0" class="block w-5 h-5 pt-0.5 rounded-full text-center text-white bg-red-500">
            {{ route.badge }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>

  <div class="block md:hidden group relative">
    <div @click="isNavMenuModalOpened = true" class="p-5 cursor-pointer">
      <mdicon name="menu"/>
    </div>
  </div>

  <v-modal-menu v-show="isNavMenuModalOpened"
                @hide="hideMenuModal()"
                w="w-[300px]"
                title="Меню"
  >
    <ul>
      <li v-for="route in routes" :key="route.path">
        <router-link @click="hideMenuModal()" :to="route.path" class="py-5 px-4 flex items-center space-x-2">
          <mdicon v-if="route.icon" :name="route.icon" class="text-primary"/>
          <span>{{ route.name }}</span>
          <div v-if="route.badge > 0"
               class="block ml-auto w-5 h-5 pt-0.5 rounded-full text-center text-white bg-red-500">
            {{ route.badge }}
          </div>
        </router-link>
      </li>
    </ul>
  </v-modal-menu>
</template>

<script>
import {mapState} from "pinia";
import {useAuthStore} from "@/stores/AuthStore";

export default {
  name: 'NavMenuComponent',

  data() {
    return {
      isNavMenuModalOpened: false,
    }
  },

  computed: {
    ...mapState(useAuthStore, {
      isUserAdmin: 'isUserAdmin',
    }),

    routes() {
      return this.$router.options.routes
          .filter(route => route.name !== undefined && route.meta.mainMenuLink === true)
          .filter(route => this.isUserAdmin ? true : route.meta.admin === undefined)
    }
  },

  methods: {
    hideMenuModal() {
      this.isNavMenuModalOpened = false
    }
  },
}
</script>
