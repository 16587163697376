import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    fetchItems(filter) {
        return ApiRequestProvider._get('items', filter)
    },

    fetchItem(id) {
        return ApiRequestProvider._get(`items/${id}`)
    },

    fetchOptions() {
        return ApiRequestProvider._get('items/options')
    },

    createItem(data) {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('type_id', data.type_id)
        formData.append('price', data.price)
        formData.append('desc', data.desc)
        formData.append('mods', Object.values(data.mods))

        Object.entries(data.actionPrices).forEach(([key, value]) => {
            formData.append(`action_prices[${key}]`, value)
        });

        return ApiRequestProvider._post('items', formData)
    },

    updateItem(id, data) {
        const formData = new FormData()

        formData.append('_method', 'PUT')
        formData.append('name', data.name)
        formData.append('type_id', data.type_id)
        formData.append('price', data.price)
        formData.append('desc', data.desc)
        formData.append('mods', Object.values(data.mods))

        Object.entries(data.actionPrices).forEach(([key, val]) => {
            formData.append(`action_prices[${key}]`, val)
        });

        return ApiRequestProvider._post(`items/${id}`, formData)
    },

}
