import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    fetchUsers(filter) {
        return ApiRequestProvider._get('users', filter)
    },

    fetchUser(id) {
        return ApiRequestProvider._get(`users/${id}`)
    },

    syncAvailableActions(id, actions) {
        return ApiRequestProvider._post(`users/${id}/syncAvailableActions`, {actions})
    },

    fetchBillableActions(id, interval) {
        return ApiRequestProvider._get(`users/${id}/billableActions?interval=${interval}`)
    },

    fetchOptions() {
        return ApiRequestProvider._get('users/options')
    },

    createUser(data) {
        const formData = new FormData()

        formData.append('role_id', data.role_id)
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        formData.append('password', data.password)
        formData.append('password_confirmation', data.passwordConfirmation)

        return ApiRequestProvider._post('users', formData)
    },

    updateUser(id, data) {
        const formData = new FormData()

        formData.append('_method', 'PUT')
        formData.append('role_id', data.role_id)
        formData.append('name', data.name)
        formData.append('phone', data.phone)

        return ApiRequestProvider._post(`users/${id}`, formData)
    },

}
