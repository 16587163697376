<template>
  <v-page-loader :loading="isItemFetching"/>

  <v-page-head :class="{'text-flow': isItemFetching}" :title="itemName">
    <template #buttons-r>
      <v-button icon="pencil-outline" @click="showItemModal('edit')"/>
    </template>
  </v-page-head>

  <div :class="{'text-flow': isItemFetching}" class="max-w-screen px-4 mx-auto grid grid-cols-3 gap-12">
    <div class="item-image flex justify-center items-center">
      <mdicon name="cube-outline" size="300"/>
    </div>

    <div class="item-info text-lg">
      <ul class="grid grid-cols-2 gap-6">
        <li>
          <span class="font-semibold">ID</span>
          <br>{{ item.id }}
        </li>

        <li>
          <span class="font-semibold">Тип</span>
          <br>{{ itemType }}
        </li>

        <li>
          <span class="font-semibold">Створено</span>
          <br><span v-html="getDateWithRelative( item.created_at )"></span>
        </li>

        <li>
          <span class="font-semibold">Змінено</span>
          <br><span v-html="getDateWithRelative( item.updated_at )"></span>
        </li>
      </ul>

      <hr class="my-6">

      <span class="font-semibold">Модифікації</span>
      <ul class="grid grid-cols-2 gap-4 text-sm">
        <li v-for="_mod in item._mods" :key="_mod.mod_id">
          <div class="w-full mb-3">
            {{ _mod.mod.category.name }}
            <span v-if="_mod.mod.category.desc" class="text-gray-400">{{ _mod.mod.category.desc }}</span>
          </div>
          <div class="w-full mb-3">
						<span
                class="p-1 text-center text-white font-semibold bg-primary border border-gray-200 rounded-lg cursor-pointer">
							{{ _mod.mod.name }}
						</span>
          </div>
        </li>
      </ul>

      <hr class="my-6">

      <span class="font-semibold">Опис</span>
      <br><span>{{ item.desc }}</span>
    </div>

    <div class="item-actions">
      <ul class="flex flex-col gap-6">
        <li v-for="action in item.action_prices" :key="action.action.id"
            class="flex items-center gap-6">
          <mdicon :name="action.action.icon" size="65"/>
          <p>
            <span>{{ action.action.name }}</span>
            <br>
            <span class="ml-auto font-bold text-2xl">{{ getMoney(action.price) }}</span>
          </p>
        </li>
      </ul>
    </div>
  </div>

  <v-modal
      v-show="isItemEditModalOpened"
      @hide="hideItemModal('edit')"
      w="w-full xl:w-4/5"
      title="Редагування товару"
  >
    <ItemCreateForm formMode="edit"/>
  </v-modal>

</template>

<script>
import {mapActions, mapState} from "pinia";
import ItemCreateForm from '@/components/Item/forms/ItemCreateForm'
import {useItemStore} from '@/stores/ItemStore';
import {getMoney} from '@/helper';

export default {

  name: 'ItemDetailsView',

  components: {
    ItemCreateForm,
  },

  data() {
    return {
      id: this.$route.params.item,
    }
  },

  computed: {
    ...mapState(useItemStore, {
      item: 'getItem',
      isItemFetching: 'isItemFetching',

      isItemEditModalOpened: 'isModalEditOpened',
    }),

    itemName() {
      return `${this.item.type?.name} "${this.item.name}"`
    },

    itemType() {
      return `[${this.item.type?.short}] ${this.item.type?.name}`
    }

  },

  methods: {
    getMoney,
    ...mapActions(useItemStore, [
      'fetchItem',
      'showItemModal',
      'hideItemModal',
    ]),

    getDate(date) {
      return this.$moment(date).format('DD.MM.YYYY HH:mm')
    },
    getRelativeDate(date) {
      return this.$moment(date).fromNow()
    },
    getDateWithRelative(date) {
      return this.$moment(date).format('DD.MM.YYYY HH:mm') + '<br>' + this.$moment(date).fromNow()
    },

  },

  async created() {
    await this.fetchItem(this.id)
  },

}

</script>
