<template>
  <div class="w-full">
    <label v-if="label" :for="id" class="w-full mb-2 flex justify-left space-x-2">
      <mdicon v-if="icon" :name="icon"/>
      <span v-if="label">{{ label }}</span>
    </label>

    <select
        class="w-full h-12 p-2 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-gray-300"
        @change="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :title="label"
        :required="required"
        :disabled="disabled"
    >
      <option value="-1" selected>- вибрати -</option>

      <option v-for="option in options" :key="option[idKey]"
              :value="option[idKey]"
              :selected="option[idKey] === selected"
      >{{ option[valueKey] }}
      </option>

    </select>
  </div>
</template>

<script>

export default {

  name: 'v-select',

  emits: ['update:modelValue'],

  props: {
    label: String,
    icon: String,
    id: String,
    modelValue: {type: [String, Number], default: ''},
    selected: {type: [String, Number], default: -1},
    required: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},

    idKey: {type: String, default: 'id'},
    valueKey: {type: String, default: 'name'},

    options: {type: Array, default: () => []},
  }

}

</script>
