const DEV = false

const BASE_PROTOCOL = (DEV) ? 'http' : 'http'
const BASE_HOST = (DEV) ? 'localhost' : 'orders.harmofemb.com.ua'
const BASE_PORT = (DEV) ? ':8080' : ''
const BASE_URL = `${BASE_PROTOCOL}://${BASE_HOST}${BASE_PORT}`

const API_PROTOCOL = (DEV) ? 'http' : 'https'
const API_HOST = (DEV) ? 'harmofemb-orders-backend.test' : 'orders-backend.harmofemb.com.ua'
const API_PORT = (DEV) ? '' : ''
const API_URL = `${API_PROTOCOL}://${API_HOST}${API_PORT}/api`

const ODB_PROTOCOL = 'https'
const ODB_HOST = 'db-backend.harmofemb.com.ua'
const ODB_URL = `${ODB_PROTOCOL}://${ODB_HOST}`

const SOCKET_PROTOCOL = (DEV) ? 'http' : 'http'
const SOCKET_HOST = (DEV) ? 'localhost' : '194.8.146.146'
const SOCKET_PORT = (DEV) ? '3000' : '3030'
const SOCKET_URL = `${SOCKET_PROTOCOL}://${SOCKET_HOST}:${SOCKET_PORT}`

module.exports = {
	appName: 'HARMOFEMB ORDERS',

	devMode: DEV,

	baseUrl: BASE_URL,
	apiUrl: API_URL,
	odbUrl: ODB_URL,

	socketUrl: SOCKET_URL,
}
