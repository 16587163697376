<template>
  <div :class="[getSize(), { 'cursor-default text-gray-300': disabled }]"
       class="w-min-12 rounded-md flex justify-center space-x-2 m8h-interactable">
    <mdicon v-if="icon" :name="icon"/>
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>

export default {

  name: 'v-button',

  props: {
    disabled: Boolean,
    text: {type: String, default: null},
    icon: {type: String, default: null},
    size: {type: String, default: 'md'}
  },

  methods: {
    getSize() {
      return {
        'xs': 'p-0',
        'sm': 'p-1',
        'md': 'p-2.5',
        'lg': 'p-3',
        'xl': 'p-4',
      }[this.size]
    }
  },

}

</script>
