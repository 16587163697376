<template>
  <div class="w-full">
    <label v-if="label" :for="id" class="w-full mb-2 flex justify-left space-x-2">
      <mdicon v-if="icon" :name="icon"/>
      <span v-if="label">{{ label }}</span>
    </label>

    <input
        class="w-full h-12 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
        type="number"
        @change="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :min="min"
        :max="max"
        :step="step"
        :value="modelValue"
        :placeholder="placeholder"
    />
  </div>
</template>

<script>

export default {

  name: 'v-input-number',

  emits: ['update:modelValue'],

  props: {
    label: String,
    placeholder: String,
    icon: String,
    id: String,

    min: String,
    max: String,
    step: {type: String, default: '1'},
    modelValue: {type: [String, Number], default: 0},
    required: {type: Boolean, default: true},

  }

}

</script>
