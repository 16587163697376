<template>
  <div class="flex justify-between space-x-4">

    <input type="checkbox" :id="id" :checked="checked" class="hidden"
           @change="$emit('update:checked', $event.target.checked)"
    />

    <label :for="id" class="p-2 flex flex-row items-center font-bold cursor-pointer select-none">
      <mdicon
          :name="{ 'checkbox-marked': checked, 'checkbox-blank-outline': !checked}"
          :class="{ 'text-blue-600': checked, 'text-gray-500': !checked}"/>

      <mdicon v-if="icon" :name="icon"/>
      {{ label }}
    </label>

  </div>
</template>

<script>

export default {

  name: 'v-checkbox',

  props: {
    label: String,
    icon: String,
    id: String,
    checked: {
      type: Boolean,
      default: false
    },
  }

}

</script>
