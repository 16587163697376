<template>
  <div class="relative">
    <div v-show="sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
      <v-loader class="my-40" :loading="sending"/>
    </div>

    <div class="flex flex-col gap-y-4">

      <ul class="w-full grid grid-cols-4 gap-2">
        <li>
          <v-select v-model.number="data.user_id" label="Замовник"
                    :options="customers"
                    :selected="data.user_id"
                    :error="errors.user_id"
          />
        </li>

        <li>
          <v-select v-model.number="data.delivery_method" label="Доставка"
                    :options="delivery"
                    :selected="data.delivery_method"
                    :error="errors.delivery_method"
          />
        </li>

        <li>
          <v-select v-model.number="data.payment_method" label="Оплата"
                    :options="payment"
                    :selected="data.payment_method"
                    :error="errors.payment_method"
          />
        </li>

        <li>
          <v-text v-model="data.desc" label="Опис" :error="errors.desc"/>
        </li>
      </ul>

      <hr v-show="formMode === 'create'" class="my-6">

      <ul v-show="formMode === 'create'" class="w-full flex flex-col gap-y-4 mb-6">
        <li v-for="(item, i) in data.items" :key="i" class="pb-6 flex flex-col items-center gap-y-2 border-b">

          <ul class="w-full flex flex-row justify-between items-start gap-2">
            <li class="w-12 py-2 text-2xl font-semibold">
              {{ i + 1 }}
            </li>

            <li class="grow">
              <ul class="w-full flex justify-between items-center gap-2">
                <li class="flex-grow">
                  <v-select v-model.number="item.item_id"
                            custom-class="p-2"
                            :options="items"
                            :selected="item.item_id"
                            @change="applyItemData(i, item.item_id)"
                  />
                </li>

                <li class="w-40">
                  <v-select-grouped v-model.number="item.material_id"
                                    :options="materials"
                                    :selected="item.material_id"
                  />
                </li>

                <li class="flex-grow">
                  <v-input v-model="item.name" placeholder="Назва"/>
                </li>

                <li class="w-16">
                  <v-input-number v-model.number="item.amount" placeholder="Кількість" min="1"/>
                </li>

                <li class="w-24">
                  <v-input-number v-model.number="item.price" placeholder="Ціна" min="1"/>
                </li>
              </ul>

              <p class="w-full mt-2">
                <v-text v-model="item.desc"/>
              </p>

              <div class="mt-2 flex flex-col">
                <div class="w-full flex justify-center items-center gap-2">
                  <span class="font-semibold">Модифікації</span>
                  <span @click="modVisibility[i] = !modVisibility[i]" class="block px-2 text-gray-400 border rounded">
                    <mdicon v-show="modVisibility[i]" name="chevron-up"/>
                    <mdicon v-show="!modVisibility[i]" name="chevron-down"/>
                  </span>
                </div>

                <ul class="item-mods mt-4 w-full grid grid-cols-6 gap-1 text-sm"
                    :class="{hidden: !modVisibility[i]}"
                >
                  <li v-for="category in mods" :key="`${i}_${category.id}`">
                    <div class="w-full mb-3">
                      <span v-if="category.name">
                        {{ category.name }}
                        <span v-if="category.desc" class="text-gray-400">{{ category.desc }}</span>
                      </span>

                      <ul class="w-full flex flex-wrap justify-start gap-0.5 leading-none">
                        <li>
                          <div
                              class=" w-6 text-center text-gray-400 cursor-pointer font-semibold hover:text-red-500"
                              @click="clearMod(i, category.slug)"
                          >
                            &#10005;
                          </div>
                        </li>
                        <li v-for="mod in category.mods"
                            :key="`${i}_${category.id}_${mod.id}`"
                        >
                          <input type="radio"
                                 v-model="item.mods[category.slug]"
                                 :id="`${i}_${category.id}_${mod.id}`"
                                 :name="category.slug"
                                 :value="mod.id"
                                 :checked="Object.keys(item.mods).includes(mod.id)"
                                 class="hidden peer"
                          >
                          <label :for="`${i}_${category.id}_${mod.id}`"
                                 class="block px-1 min-w-[3rem] text-center text-gray-400 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white font-semibold hover:text-gray-600 hover:bg-gray-100">
                            {{ mod.name }}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li class="shrink">
              <div
                  class="w-12 p-4 text-gray-400 cursor-pointer font-semibold hover:text-red-500 select-none"
                  @click="clearItem(i)"
              >
                &#10005;
              </div>
            </li>

          </ul>

        </li>
      </ul>

      <div v-show="formMode === 'create'"
           @click="addItem()"
           class="w-full p-4 border-dashed border-gray-200 border-2 text-gray-300 rounded-xl hover:border-gray-400 cursor-pointer">
        <div class="w-6 mx-auto">
          <mdicon name="plus"/>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer text-right">
    <button @click="createOrder(data)"
            v-if="formMode === 'create'"
            class="bg-primary text-white mt-5 p-4 rounded-lg"
            type="button">Створити
    </button>

    <button @click="updateOrder(data)"
            v-if="formMode === 'edit'"
            class="bg-primary text-white mt-5 p-4 rounded-lg"
            type="button">Зберегти
    </button>
  </div>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {useItemStore, useOrderStore} from "@/stores";

export default {

  name: 'OrderCreateForm',

  props: {
    formMode: {type: String, default: 'create'},
  },

  data() {
    return {
      data: {
        user_id: null,
        delivery_method: null,
        payment_method: null,
        desc: '',

        items: [],
      },

      modVisibility: [],
    }
  },

  computed: {
    ...mapState(useItemStore, {
      items: 'getItems',
      types: 'getTypes',
      mods: 'getMods',
      actions: 'getActions',
    }),

    ...mapState(useOrderStore, {
      order: 'getOrder',
      materials: 'getMaterials',
      customers: 'getCustomers',
      delivery: 'getDelivery',
      payment: 'getPayment',
      isTypesFetching: 'isTypesFetching',
      isModsFetching: 'isModsFetching',
      isActionsFetching: 'isActionsFetching',
      errors: 'getErrors',
      sending: 'isSendingData',
    }),
  },

  methods: {
    ...mapActions(useItemStore, [
      'fetchItems',
      'fetchItemDirect',
      'fetchItemOptions',
    ]),

    ...mapActions(useOrderStore, [
      'createOrder',
      'updateOrder',
      'fetchOrderOptions',
    ]),

    addItem() {
      const index = this.modVisibility.length - 1
      this.modVisibility[index] = false

      this.data.items.push({
        item_id: null,
        material_id: null,
        name: '',
        amount: 1,
        price: null,
        desc: '',
        mods: {},
      })
    },

    clearItem(index) {
      this.data.items = this.data.items.filter((item, i) => i !== index)
      this.modVisibility = this.modVisibility.filter((item, i) => i !== index)
    },

    async applyItemData(index, id) {
      if (id === -1) {
        this.data.items[index].mods = {}
      } else {
        const item = await this.fetchItemDirect(id)

        this.data.items[index].price = item.price

        this.data.items[index].mods = {}
        item._mods.forEach(mod => {
          this.data.items[index].mods[mod.mod.category.slug] = mod.mod_id
        })
      }
    },

    clearMod(index, slug) {
      delete this.data.items[index].mods[slug]
    },

  },

  watch: {
    order(newOrder) {
      this.data = newOrder
    }
  },

  created() {
    this.fetchItems()
    this.fetchItemOptions()
    this.fetchOrderOptions()
  }
}

</script>
