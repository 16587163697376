import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    fetchActions() {
        return ApiRequestProvider._get('actions')
    },

    createAction(data) {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('icon', data.icon)
        formData.append('price', data.price)
        formData.append('desc', data.desc)

        return ApiRequestProvider._post('actions', formData)
    },

    updateItem(id, data) {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('icon', data.icon)
        formData.append('price', data.price)
        formData.append('desc', data.desc)

        return ApiRequestProvider._post(`actions/${id}`, formData)
    },

}
