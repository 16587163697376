<template>
  <v-page-loader :loading="isItemsFetching"/>

  <v-page-head :title="$route.matched[0].name">
    <template #buttons-r>
      <v-button icon="reload" @click="fetchItems()"/>
      <v-button icon="plus" @click="showItemModal('create')" class="m8h-interactable"/>
    </template>
  </v-page-head>

  <div class="max-w-screen p-6 mx-auto grid grid-cols-2 md:grid-cols-3 gap-6">
    <ItemItem
        v-for="item in items"
        :key="item.id"
        :item="item"
    />
  </div>

  <v-modal v-show="isItemCreateModalOpened"
           @hide="hideItemModal('create')"
           w="w-full md:w-4/5"
           title="Новий товар"
  >
    <ItemCreateForm/>
  </v-modal>

</template>

<script>
import {mapActions, mapState} from 'pinia';

import ItemItem from '@/components/Item/ItemItem';
import ItemCreateForm from '@/components/Item/forms/ItemCreateForm'

import {useItemStore} from '@/stores/ItemStore';

export default {
  name: 'ItemsView',

  components: {
    ItemItem,
    ItemCreateForm,
  },

  computed: {
    ...mapState(useItemStore, {
      items: 'getItems',
      roles: 'getRoles',
      isItemsFetching: 'isItemsFetching',

      isItemCreateModalOpened: 'isModalCreateOpened',
    })
  },

  methods: {
    ...mapActions(useItemStore, [
      'fetchItems',
      'fetchOptions',
      'showItemModal',
      'hideItemModal',
    ]),
  },

  async created() {
    await this.fetchItems()
  },
}

</script>
