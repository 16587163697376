<template>
  <div class="user-item flex items-center bg-white rounded-xl shadow-md hover:shadow-xl transition group relative">

    <div class="user-image p-2 flex-0">
      <mdicon name="account-outline" size="60"/>
    </div>

    <div class="grow">
      <h4 class="font-semibold" :title="user.name">
        <router-link :to="`/users/${user.id}`">
          {{ user.name }}
        </router-link>
      </h4>
      <dic class="flex items-center gap-2 text-sm">
        <mdicon name="circle" :class="`text-${user.role.color}`" size="16" />
        {{ user.role.name }}
      </dic>
    </div>

  </div>
</template>

<script>

export default {

  name: 'UserItem',

  props: {
    user: Object
  },

}

</script>
