import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    async fetchOrders(filter) {
        return await ApiRequestProvider._get('orders', filter)
    },

    async fetchOrder(id) {
        return await ApiRequestProvider._get(`orders/${id}`)
    },

    async fetchOrderItem(id) {
        return await ApiRequestProvider._get(`orderItems/${id}`)
    },

    async fetchOptions() {
        return await ApiRequestProvider._get('orders/options')
    },

    async fetchOrderItemActions(id) {
        return await ApiRequestProvider._get(`orders/${id}/itemActions`)
    },


    async createOrder(data) {
        const formData = new FormData()

        formData.append('user_id', data.user_id)
        formData.append('delivery_method', data.delivery_method)
        formData.append('payment_method', data.payment_method)
        formData.append('desc', data.desc)
        this.buildItems(formData, data.items)

        return await ApiRequestProvider._post('orders', formData)
    },

    async updateOrder(data) {
        const formData = new FormData()

        formData.append('_method', 'PUT')
        formData.append('user_id', data.user_id)
        formData.append('delivery_method', data.delivery_method)
        formData.append('payment_method', data.payment_method)
        formData.append('desc', data.desc)

        return await ApiRequestProvider._post(`orders/${data.id}`, formData)
    },


    async createOrderItem(data) {
        const formData = new FormData()

        formData.append(`order_id`, data.order_id)
        formData.append(`item_id`, data.item_id)
        Object.entries(data.mods).forEach(mod_id => {
            formData.append(`mods[][mod_id]`, mod_id[1])
        });
        formData.append(`material_id`, data.material_id)
        formData.append(`name`, data.name)
        formData.append(`amount`, data.amount)
        formData.append(`price`, data.price)
        formData.append(`desc`, data.desc)

        return await ApiRequestProvider._post('orderItems', formData)
    },

    async updateOrderItem(data) {
        const formData = new FormData()

        formData.append('_method', 'PUT')
        formData.append(`order_id`, data.order_id)
        formData.append(`item_id`, data.item_id)
        Object.entries(data.mods).forEach(mod_id => {
            formData.append(`mods[][mod_id]`, mod_id[1])
        });
        formData.append(`material_id`, data.material_id)
        formData.append(`name`, data.name)
        formData.append(`amount`, data.amount)
        formData.append(`price`, data.price)
        formData.append(`desc`, data.desc)

        return await ApiRequestProvider._post(`orderItems/${data.id}`, formData)
    },


    async setOrderFlag(id, flag) {
        return await ApiRequestProvider._post(`orders/${id}/setFlag`, flag)
    },

    async setOrderItemFlag(id, flag) {
        return await ApiRequestProvider._post(`orderItems/${id}/setFlag`, flag)
    },

    buildItems(formData, items) {
        Object.entries(items).forEach(([key, value]) => {
            formData.append(`items[${key}][item_id]`, value.item_id)

            Object.entries(value.mods).forEach(mod_id => {
                formData.append(`items[${key}][mods][][mod_id]`, mod_id[1])
            });

            formData.append(`items[${key}][material_id]`, value.material_id)
            formData.append(`items[${key}][name]`, value.name)
            formData.append(`items[${key}][amount]`, value.amount)
            formData.append(`items[${key}][price]`, value.price)
            formData.append(`items[${key}][desc]`, value.desc)
        });
    }
}
