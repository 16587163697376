<template>
  <div id="UserLoginFrame" class="sm:max-w-sm bg-white shadow-lg mx-auto mt-48 border-0 rounded-xl">
    <div class="text-center p-6">
      <!--             <img src="/public/img/main-logo-min.png" alt="" width="100" height="100" class="mt-4">-->
      <h2 class="my-8 text-4xl font-bold text-primary">HARMOFEMB ORDERS</h2>
      <h4 class="my-5 text-gray-500">Вхід</h4>

      <AuthForm/>

    </div>


  </div>
</template>

<script>

import AuthForm from '@/components/Auth/AuthForm'

export default {
  name: 'AuthView',

  components: {
    AuthForm,
  },


}

</script>
