<template>
  <div class="relative">
    <div v-show="sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
      <v-loader class="my-40" :loading="sending"/>
    </div>

    <form>
      <ul class="grid grid-cols-2 gap-6">
        <li>
          <v-input
              v-model="data.name"
              id="UserModalName"
              label="Імʼя"
              type="text"
              :error="errors.name"
          />
        </li>
        <li>
          <v-select
              v-model="data.role_id"
              id="UserModalRole"
              label="Роль"
              :options="roles"
              :selected="data.role_id"
              :error="errors.role_id"
          />
        </li>
        <li>
          <v-input
              v-model="data.email"
              id="UserModalEmail"
              label="Email"
              type="email"
              :error="errors.email"
              :disabled="true"
          />
        </li>
        <li>
          <v-input
              v-model="data.phone"
              id="UserModalPhone"
              label="Номер телефону"
              type="tel"
              :error="errors.phone"
          />
        </li>
        <li>
          <v-input
              v-model="data.password"
              id="UserModalPassword"
              label="Пароль"
              type="password"
              :error="errors.password"
          />
        </li>
        <li>
          <v-input
              v-model="data.passwordConfirmation"
              id="UserModalPassword"
              label="Підтвердження паролю"
              type="password"
              :error="errors.password"
          />
        </li>

      </ul>

      <div class="modal-footer text-right">
        <button @click="reset()"
                class="mt-5 p-4 rounded-lg modal-close"
                type="button">Скасувати
        </button>

        <button @click="addUser(data)"
                v-if="formMode === 'create'"
                class="bg-primary text-white mt-5 p-4 rounded-lg"
                type="button">Створити
        </button>

        <button @click="updateUser(data)"
                v-if="formMode === 'edit'"
                class="bg-primary text-white mt-5 p-4 rounded-lg"
                type="button">Зберегти
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";

import {useUserStore} from "@/stores/UserStore";

export default {

  name: 'UserCreateForm',

  props: {
    formMode: {type: String, default: 'create'},
  },

  data() {
    return {
      data: {
        role_id: 0,
        name: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirmation: '',
      },

    }
  },

  computed: {
    ...mapState(useUserStore, {
      user: 'getUser',
      roles: 'getRoles',
      isRolesFetching: 'isRolesFetching',
      errors: 'getErrors',
      sending: 'isSendingData',
    }),
  },

  methods: {
    ...mapActions(useUserStore, [
      'fetchOptions',
      'addUser',
      'updateUser',
    ]),

    reset() {
      this.data.role_id = this.user.role_id
      this.data.name = this.user.name
      this.data.email = this.user.email
      this.data.phone = this.user.phone
      this.data.password = this.user.password
      this.data.passwordConfirmation = this.user.passwordConfirmation
    },
  },

  watch: {
    user(newUser) {
      this.data.role_id = newUser.role_id
      this.data.name = newUser.name
      this.data.email = newUser.email
      this.data.phone = newUser.phone
    }
  },

  created() {
    this.fetchOptions()
  }
}

</script>
