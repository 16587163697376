import UsersView from "@/views/User/UsersView.vue";
import UsersList from "@/views/User/UsersList.vue";
import UserDetailsView from "@/views/User/UserDetailsView.vue";

export const userRoutes = {
    path: '/users',
    component: UsersView,
    name: 'Користувачі',
    icon: 'account-outline',
    meta: {
        displayHeader: true,
        mainMenuLink: true,
        admin: true,
    },
    children: [
        {
            path: '',
            component: UsersList,
        },
        {
            path: ':user',
            component: UserDetailsView,
            name: 'Деталі користувача',
        },
    ],
}