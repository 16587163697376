import OrdersView from "@/views/Order/OrdersView.vue";
import OrdersList from "@/views/Order/OrdersList.vue";
import OrderDetailsView from "@/views/Order/OrderDetailsView.vue";

export const orderRoutes = {
    path: '/orders',
    component: OrdersView,
    name: 'Замовлення',
    icon: 'list-box-outline',
    badge: 0,
    meta: {
        displayHeader: true,
        mainMenuLink: true,
    },
    children: [
        {
            path: '',
            component: OrdersList,
        },
        {
            path: ':order',
            component: OrderDetailsView,
            name: 'Деталі замовлення',
        },
    ],
}