<template>
  <div v-show="auth" class="group relative">

    <div @click="isOpen = !isOpen" class="dropdown flex justify-between items-center cursor-pointer">

      <LiteralAvatar :name="user?.name"/>

      <div class="flex justify-between items-center">
        <span class="font-semibold hidden lg:inline">{{ user?.name }}</span>

        <div v-show="user?.badge > 0" class="w-6 h-6 inline-block rounded-full text-center text-white bg-red-500">
          {{ user?.badge }}
        </div>

        <div class="pr-2 hidden lg:inline">
          <mdicon name="chevron-down"/>
        </div>

      </div>
    </div>

    <transition name="fade" appear>
      <ul v-show="isOpen"
          class="dropdown-menu absolute top-full right-0 w-40 rounded-md shadow-lg bg-white overflow-hidden z-10">

        <li v-for="item in items" :key="item.text" class="hover:bg-gray-50 ">
          <a @click="item.action" class="p-4 leading-none flex justify-left items-center space-x-3 cursor-pointer">
            <mdicon :name="item.icon"/>
            <span>{{ item.text }}</span>
          </a>
        </li>

      </ul>
    </transition>

  </div>

</template>

<script>
import {mapState} from "pinia";
import {useAuthStore} from '@/stores/AuthStore'
import LiteralAvatar from "@/components/_ui/LiteralAvatar.vue";

export default {
  name: 'NavUserComponent',
  components: {LiteralAvatar},

  data() {
    return {
      isOpen: false,

      items: [
        {icon: 'account-outline', text: 'Profile'},
        {icon: 'logout', text: 'Logout', action: this.logout},
      ]
    }
  },

  computed: {
    ...mapState(useAuthStore, {
      auth: 'isUserAuth',
      user: 'getUser',
    })
  },

  methods: {
    async logout() {
      const authStore = useAuthStore()
      await authStore.logout()
    }
  },

  created() {
    if (!this.auth) this.$router.push('/auth')
  }
}
</script>
