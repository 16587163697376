import ApiRequestProvider from '@/providers/ApiRequestProvider'
import { loginRequest } from '@/requests/AuthRequests'

export default {

    async login( data ) {
        const validation = loginRequest(data)
        if( validation.data.errors?.length > 0 ) return validation

        return ApiRequestProvider._post( 'auth/login', data )
    },

    async logout() {
        return ApiRequestProvider._get( 'auth/logout' )
    },

    async current() {
        return ApiRequestProvider._get( 'auth/user' )
    },

}
