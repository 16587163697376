<template>
  <v-page-loader :loading="isUsersFetching"/>

  <v-page-head :title="$route.matched[0].name">
    <template #buttons-r>
      <v-button icon="reload" @click="fetchUsers()"/>
      <v-button icon="plus" @click="showUserModal('create')"/>
    </template>
  </v-page-head>

  <div class="max-w-screen p-6 mx-auto grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-6">
    <h3 class="col-span-full font-semibold">Працівники</h3>
    <UserItem
        v-for="user in employees"
        :key="user.id"
        :user="user"
    />

    <h3 class="col-span-full font-semibold">Клієнти</h3>
    <UserItem
        v-for="user in customers"
        :key="user.id"
        :user="user"
    />

    <h3 class="col-span-full font-semibold">Менеджери</h3>
    <UserItem
        v-for="user in managers"
        :key="user.id"
        :user="user"
    />

    <h3 class="col-span-full font-semibold">Адміністрація</h3>
    <UserItem
        v-for="user in admins"
        :key="user.id"
        :user="user"
    />
  </div>

  <v-modal v-show="isUserCreateModalOpened"
           @hide="hideUserModal('create')"
           w="w-full md:w-4/5"
           title="Новий користувач"
  >
    <UserCreateForm/>
  </v-modal>

</template>

<script>
import {mapActions, mapState} from 'pinia';

import UserItem from '@/components/User/UserItem';
import UserCreateForm from '@/components/User/forms/UserCreateForm'

import {useUserStore} from '@/stores/UserStore';

export default {
  name: 'UsersView',

  components: {
    UserItem,
    UserCreateForm,
  },

  computed: {
    ...mapState(useUserStore, {
      users: 'getUsers',
      roles: 'getRoles',
      isUsersFetching: 'isUsersFetching',

      isUserCreateModalOpened: 'isModalCreateOpened',
    }),

    employees() {
      return this.users.filter(user => user.role_id === 4)
    },
    customers() {
      return this.users.filter(user => user.role_id === 3)
    },
    managers() {
      return this.users.filter(user => user.role_id === 2)
    },
    admins() {
      return this.users.filter(user => user.role_id === 1)
    },
  },

  methods: {
    ...mapActions(useUserStore, [
      'fetchUsers',
      'fetchOptions',
      'showUserModal',
      'hideUserModal',
    ]),
  },

  watch: {
    employees(newEmployees) {
      this.employees = newEmployees
    },
    customers(newCustomers) {
      this.customers = newCustomers
    },
    managers(newManagers) {
      this.managers = newManagers
    },
    admins(newAdmins) {
      this.admins = newAdmins
    },
  },

  async created() {
    await this.fetchUsers()
  },
}

</script>
