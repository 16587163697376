import {reactive} from "vue";
import {io} from "socket.io-client";
import {socketUrl} from '@/config'

export const state = reactive({
    connected: false,
    orderEvents: [],
});

export const socket = io(socketUrl);

// socket.on("connect", () => {
//     state.connected = true;
// });
//
// socket.on("disconnect", () => {
//     state.connected = false;
// });
