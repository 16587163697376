import { isEmail } from '@/helper'

const ERROR_TEXT = {
    EMAIL_REQUIRED: 'Некоректний email',
    PASSW_REQUIRED: 'Довжина паролю менша 5 симолів',
}

export const loginRequest = req => {
    const data = { errors: [] }

    if( !isEmail( req.email ) ) {
        data.errors.push( ERROR_TEXT.EMAIL_REQUIRED )
    }

    if( req.password.length <= 4 ) {
        data.errors.push( ERROR_TEXT.PASSW_REQUIRED )
    }

    return { data }
}
