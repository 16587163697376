const ERROR_TEXT = {
	NAME_LENGTH: 'Довжина імені менша 2 символів',
	ROLE_REQUIRED: 'Роль не вказана',
	EMAIL_REQUIRED: 'Email не вказаний',
	PHONE_REQUIRED: 'Номер телефону не вказаний',
}

exports.createUserRequest = req => {
	const errors = {}

	if ( req.name.length < 2 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.role_id.length === 0 ) {
		errors.role_id = ERROR_TEXT.ROLE_REQUIRED
	}

	if ( req.email.length === 0 ) {
		errors.email = ERROR_TEXT.EMAIL_REQUIRED
	}

	if ( req.phone.length === 0 ) {
		errors.phone = ERROR_TEXT.PHONE_REQUIRED
	}

	return errors
}

exports.updateUserRequest = req => {
	const errors = {}

	if ( req.name.length < 2 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.role_id.length === 0 ) {
		errors.role_id = ERROR_TEXT.ROLE_REQUIRED
	}

	if ( req.phone.length === 0 ) {
		errors.phone = ERROR_TEXT.PHONE_REQUIRED
	}

	return errors
}
