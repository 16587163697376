<template>
  <div class="w-full">
    <label v-if="label" :for="id" class="w-full mb-2 flex justify-left space-x-2">
      <mdicon v-if="icon" :name="icon"/>
      <span v-if="label">{{ label }}</span>
    </label>

    <input
        class="w-full h-12 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
        :class="{disabled: 'bg-gray-400'}"
        :type="type"
        @change="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :value="modelValue"
        :placeholder="placeholder"
    />
    <p v-if="error.length > 0" class="text-red-500">{{ error }}</p>
  </div>
</template>

<script>

export default {

  name: 'v-input',

  emits: ['update:modelValue'],

  props: {
    label: String,
    placeholder: String,
    icon: String,
    id: String,

    type: {type: String, default: 'text'},

    error: {type: String, default: ''},

    modelValue: {type: [String, Number], default: ''},

    autofocus: {type: Boolean, default: false},
    required: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
  }

}

</script>
