<template>
  <div class="item-item h-20 flex justify-between items-center bg-white rounded-xl shadow-md hover:shadow-xl transition group relative">

    <div class="item-image p-2 flex-none">
      <router-link :to="`/items/${item.id}`">
        <mdicon name="cube-outline" size="60"/>
      </router-link>
    </div>

    <div class="item-info grow pl-0">
      <ul class="grid grid-cols-2 items-center">
        <li class="col-span-full">
          <h4 :title="item.name">
            <router-link :to="`/items/${item.id}`">
              <span>{{ item.type?.name }}</span>
              <span class="font-semibold">{{ ' ' + item.name }}</span>
            </router-link>
          </h4>
          <p class="text-gray-400">{{ item.desc }}</p>
          <span class="font-mono">{{ getMoney(item.price) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {getMoney} from "@/helper";

export default {

  name: 'ItemItem',
  methods: {getMoney},

  props: {
    item: Object
  },

}

</script>
