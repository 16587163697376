module.exports = {
	isEmail( email ) {
		const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
		return regex.test( email )
	},

	getPhone( phone ) {
		return phone.replace( /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3 $4 $5" )
	},

	getRandomInt( min = 0, max = 1, bothInclusive = true ) {
		return Math.floor( Math.random() * (max - min + Number( bothInclusive )) + min )
	},

	getRandomFloat( min = 0, max = 1, bothInclusive = true, fixed = 2 ) {
		return parseFloat( (Math.random() * (max - min + Number( bothInclusive )) + min).toFixed( fixed ) )
	},

	getAcronym( string ) {
		return (string.length) ? string.match(/\b(\w)/g).join('') : ''
	},

	getMoney( value = 0 ) {
		return value.toLocaleString( 'uk-UA', {
			style: 'currency',
			currency: 'UAH',
		})
	},

	// Files
	getFileSize( item ) {
		let size = 0

		// Kilobytes
		if ( item.size >= 1000 ) size = (item.size / 1000).toFixed( 2 ) + ' Кб'
		// Megabytes
		if ( item.size >= 1000000 ) size = (item.size / 1000000).toFixed( 2 ) + ' Мб'
		// Gigabytes
		if ( item.size >= 1000000000 ) size = (item.size / 1000000000).toFixed( 2 ) + ' Гб'

		return size
	},
}

