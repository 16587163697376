<template>
  <div
      class="order-item h-20 flex justify-between items-center bg-white rounded-3xl hover:shadow-md transition group relative">

    <div  class="order-image p-2 flex-none">
      <mdicon v-if="edit" name="pencil-outline" size="60"/>
      <router-link v-else :to="`/orders/${order.id}`">
        <mdicon name="list-box-outline" size="60"/>
      </router-link>
    </div>

    <div class="order-info grow pl-0">
      <ul class="grid grid-cols-2 items-center">
        <li class="col-span-full">
          <ul class="pr-2 flex flex-wrap justify-between items-center gap-4">
            <li>
              <h4 class="font-bold" :title="`Замовлення #${order.id}`">
                <span v-if="edit">{{ `Замовлення #${order.id} - радегується` }}</span>
                <router-link v-else :to="`/orders/${order.id}`">
                  {{ `Замовлення #${order.id}` }}
                </router-link>
              </h4>
            </li>

            <li v-show="isUserAdmin">
              {{ order.user.name }}
            </li>

            <li v-show="isUserAdmin" class="font-mono">
              {{ getMoney(order.price_total) }}
            </li>

            <li class="text-right">
              <v-date :date="order.created_at"/>
            </li>
          </ul>
          <p class="text-gray-400">{{ order.desc }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {getMoney} from "@/helper";
import {mapState} from "pinia";
import {useAuthStore} from "@/stores";

export default {

  name: 'OrderItem',
  methods: {getMoney},

  props: {
    order: Object,
    edit: {type: Boolean, default: false}
  },

  computed: {
    ...mapState(useAuthStore, {
      isUserAdmin: 'isUserAdmin'
    }),
  },
}

</script>
