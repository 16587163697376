import {defineStore} from 'pinia'

import OrderService from '@/services/OrderService'
import OrderActionService from '@/services/OrderActionService'

export const useOrderStore = defineStore('order', {

    state: () => ({
        orders: [],
        order: {
            id: null,
            user_id: null,
            delivery_method: null,
            payment_method: null,
            desc: '',

            items: [],
        },

        item: {
            order_id: null,
            item_id: null,
            material_id: null,
            name: null,
            price: null,
            desc: null,

            mods: {}
        },

        customers: [],
        employees: [],
        materials: [],
        delivery: [],
        payment: [],

        loading: {
            orders: false,
            order: false,

            item: false,

            customers: false,
            employees: false,
            materials: false,
            delivery: false,
            payment: false,

            sending: false,
        },

        modal: {
            order: {
                create: false,
                edit: false,
            },
            item: {
                create: false,
                edit: false,
            },
        },

        errors: [],
    }),

    getters: {
        getOrders(state) {
            return state.orders;
        },

        isOrdersFetching(state) {
            return state.loading.orders
        },


        getOrder(state) {
            return state.order;
        },

        isOrderFetching(state) {
            return state.loading.order
        },


        getOrderItem(state) {
            return state.item;
        },

        isOrderItemFetching(state) {
            return state.loading.item
        },


        getCustomers(state) {
            return state.customers;
        },

        isCustomersFetching(state) {
            return state.loading.customers
        },


        getEmployees(state) {
            return state.employees;
        },

        isEmployeesFetching(state) {
            return state.loading.employees
        },


        getMaterials(state) {
            return state.materials;
        },

        isMaterialsFetching(state) {
            return state.loading.materials
        },


        getDelivery(state) {
            return state.delivery;
        },

        isDeliveryFetching(state) {
            return state.loading.delivery
        },


        getPayment(state) {
            return state.payment;
        },

        isPaymentFetching(state) {
            return state.loading.payment
        },


        getErrors(state) {
            return state.errors
        },


        isSendingData(state) {
            return state.loading.sending
        },


        isModalCreateOpened(state) {
            return state.modal.order.create
        },

        isModalEditOpened(state) {
            return state.modal.order.edit
        },

        isModalItemCreateOpened(state) {
            return state.modal.item.create
        },

        isModalItemEditOpened(state) {
            return state.modal.item.edit
        },
    },

    actions: {
        async fetchOrders(filter) {
            this.loading.orders = true

            this.order = {}
            this.item = {}

            const res = await OrderService.fetchOrders(filter)

            try {
                this.orders = res.data.data
            } catch (e) {
                this.orders = []
            }

            this.loading.orders = false
        },

        async fetchOrdersLight() {
            const res = await OrderService.fetchOrders()

            try {
                this.orders = res.data.data
            } catch (e) {
                this.orders = []
            }
        },

        async fetchOrder(id) {
            this.loading.order = true

            this.item = {}

            const res = await OrderService.fetchOrder(id)

            try {
                this.order = res.data.data
            } catch (e) {
                this.order = {}
            }

            this.loading.order = false
        },

        async fetchOrderItem(id) {
            this.loading.item = true
            this.item = {}

            const res = await OrderService.fetchOrderItem(id)

            try {
                this.item = res.data.data
            } catch (e) {
                this.item = {}
            }

            this.loading.item = false
        },

        async fetchOrderItemActions() {
            const res = await OrderService.fetchOrderItemActions(this.order.id)

            try {
                this.order.items.map(item => {
                    item.actions = res.data.data.filter(i => i.order_item_id === item.id)
                })
            } catch (e) {
                this.order = {}
            }
        },

        async fetchOrderOptions() {
            this.loading.customers = true
            this.loading.materials = true
            this.loading.delivery = true
            this.loading.payment = true

            const res = await OrderService.fetchOptions()

            try {
                this.customers = res.data.data.customers
                this.materials = res.data.data.materials
                this.delivery = res.data.data.delivery
                this.payment = res.data.data.payment
            } catch (e) {
                this.customers = []
                this.materials = []
                this.delivery = []
                this.payment = []
            } finally {
                this.loading.customers = false
                this.loading.materials = false
                this.loading.delivery = false
                this.loading.payment = false
            }

        },


        async createOrder(order) {
            this.loading.sending = true

            // this.errors = createOrderRequest(order)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await OrderService.createOrder(order)

            try {
                if (res.data.status) {
                    this.hideOrderModal('create')
                    await this.fetchOrders()
                }
            } catch (e) {
                //
            }

            this.loading.sending = false

        },

        async updateOrder(order) {
            this.loading.sending = true

            // this.errors = updateOrderRequest(data)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await OrderService.updateOrder(order)

            try {
                if (res.data.status) {
                    this.hideOrderModal('edit')
                    await this.fetchOrder(this.order.id)
                }
            } catch (e) {
                //
            }

            this.loading.sending = false
        },


        async createOrderItem(item) {
            this.loading.sending = true

            const res = await OrderService.createOrderItem(item)

            try {
                if (res.data.status) {
                    this.hideOrderItemModal('create')
                    await this.fetchOrder(this.order.id)
                }
            } catch (e) {
                //
            }

            this.loading.sending = false

        },

        async updateOrderItem(item) {
            this.loading.sending = true

            const res = await OrderService.updateOrderItem(item)

            try {
                if (res.data.status) {
                    this.hideOrderItemModal('edit')
                    // await this.fetchOrder(this.order.id)
                }
            } catch (e) {
                //
            }

            this.loading.sending = false
        },


        async makeItemAction(item_id, action_id) {
            await OrderActionService.makeAction(this.order.id, item_id, action_id)
        },


        async setOrderFlag(flag) {
            await OrderService.setOrderFlag(this.order.id, flag)
        },

        async setOrderItemFlag(item_id, flag) {
            await OrderService.setOrderItemFlag(item_id, flag)
        },


        showOrderModal(name) {
            this.modal.order[name] = true
        },

        hideOrderModal(name) {
            this.modal.order[name] = false
        },

        toggleOrderModal(name) {
            this.modal.order[name] = !this.modal.order[name]
        },


        showOrderItemModal(name) {
            this.modal.item[name] = true
        },

        hideOrderItemModal(name) {
            this.modal.item[name] = false
        },

        toggleOrderItemModal(name) {
            this.modal.item[name] = !this.modal.item[name]
        },
    },

})
