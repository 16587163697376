import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/css/app.css'

const app = createApp( App )

const pinia = createPinia()
app.use( pinia )

import router from './router'
app.use( router )

import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
app.use( mdiVue, { icons: mdijs } )

import moment from 'moment'
import 'moment/locale/uk'
moment.locale( 'uk' )

// Register global components
import components from '@/components/_ui'
components.forEach(component => {
    app.component( component.name, component )
})

app.config.globalProperties.$moment = moment

app.mount( '#app' )
