<template>
  <header v-if="$route.meta?.displayHeader" class="antialiased leading-none text-gray-500">
    <NavbarComponent title="HARMOFEMB ORD" icon="list-box-outline">
      <!--            <template #search><NavSearchComponent class="mr-auto"/></template>-->
      <template #menu>
        <NavMenuComponent/>
      </template>
      <template #volume>
        <NavVolumeComponent/>
      </template>
      <template #notification>
        <NavNotificationComponent/>
      </template>
      <template #user>
        <NavUserComponent/>
      </template>
    </NavbarComponent>
  </header>
  <main class="antialiased leading-none text-gray-500">
    <router-view/>
  </main>
</template>

<script>
import {useAuthStore} from '@/stores/AuthStore'

import NavbarComponent from '@/components/_ui/nav/NavbarComponent'

import NavVolumeComponent from '@/components/_ui/nav/NavVolumeComponent'
import NavMenuComponent from '@/components/_ui/nav/NavMenuComponent'
// import NavSearchComponent from '@/components/_ui/nav/NavSearchComponent'
import NavNotificationComponent from '@/components/_ui/nav/NavNotificationComponent'
import NavUserComponent from '@/components/_ui/nav/NavUserComponent'
import {mapActions} from "pinia";

export default {
  name: 'App',
  components: {
    NavbarComponent,

    NavVolumeComponent,
    // NavSearchComponent,
    NavNotificationComponent,
    NavUserComponent,
    NavMenuComponent,
  },

  methods: {
    ...mapActions(useAuthStore, [
        'fetchCurrentUser'
    ])
  },

  created() {
    this.fetchCurrentUser()
  }
}
</script>
