import {defineStore} from 'pinia'

import UserService from '@/services/UserService'

import {createUserRequest, updateUserRequest} from '@/requests/UserRequests'

export const useUserStore = defineStore('user', {

    state: () => ({
        users: [],
        user: {},
        userBillableActions: [],
        userAvailableActions: [],

        roles: [],

        loading: {
            users: false,
            user: false,
            userBillableActions: false,
            userAvailableActions: false,
            sending: false,
        },

        modal: {
            create: false,
            edit: false,
        },

        errors: [],
    }),

    getters: {
        getUsers(state) {
            return state.users;
        },

        isUsersFetching(state) {
            return state.loading.users
        },


        getUser(state) {
            return state.user;
        },

        isUserFetching(state) {
            return state.loading.user
        },

        isUserAdmin(state) {
            return state.user ? state.user.role_id === 1 : false
        },

        isUserManager(state) {
            return state.user ? state.user.role_id === 2 : false
        },

        isUserCustomer(state) {
            return state.user ? state.user.role_id === 3 : false
        },

        isUserEmployee(state) {
            return state.user ? state.user.role_id === 4 : false
        },


        getUserAvailableActions(state) {
            return state.userAvailableActions
        },

        isUserAvailableActionsFetching(state) {
            return state.loading.userAvailableActions
        },


        getUserBillableActions(state) {
            return state.userBillableActions
        },

        isUserBillableActionsFetching(state) {
            return state.loading.userBillableActions
        },


        getRoles(state) {
            return state.roles;
        },

        isRoleFetching(state) {
            return state.loading.roles
        },


        getErrors(state) {
            return state.errors
        },


        isSendingData(state) {
            return state.loading.sending
        },


        isModalCreateOpened(state) {
            return state.modal.create
        },

        isModalEditOpened(state) {
            return state.modal.edit
        },
    },

    actions: {
        async fetchUsers(filter) {
            this.loading.users = true

            this.user = {}
            const res = await UserService.fetchUsers(filter)

            try {
                this.users = res.data.data
            } catch (e) {
                this.users = []
            }

            this.loading.users = false
        },

        async fetchUser(id) {
            this.loading.user = true

            const res = await UserService.fetchUser(id)

            try {
                this.user = res.data.data
            } catch (e) {
                this.user = {}
            }

            this.loading.user = false
        },

        async syncAvailableActions(id, actions) {
            this.loading.userAvailableActions = true

            const res = await UserService.syncAvailableActions(id, actions)

            try {
                this.userAvailableActions = res.data.data
            } catch (e) {
                this.userAvailableActions = []
            }

            this.loading.userAvailableActions = false
        },

        async fetchBillableActions(id, interval) {
            this.loading.userBillableActions = true

            const res = await UserService.fetchBillableActions(id, interval)

            try {
                this.userBillableActions = res.data.data
            } catch (e) {
                this.userBillableActions = []
            }

            this.loading.userBillableActions = false
        },

        async fetchOptions() {
            this.loading.roles = true

            const res = await UserService.fetchOptions()

            try {
                this.roles = res.data.data.roles
            } catch (e) {
                this.roles = []
            }

            this.loading.roles = false
        },

        async addUser(user) {
            this.loading.sending = true

            this.errors = createUserRequest(user)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await UserService.createUser(user)

            try {
                if (res.data.status) {
                    this.hideUserModal('create')
                    await this.fetchUsers()
                }
            } catch (e) {
                //
            }

            this.loading.sending = false

        },

        async updateUser(data) {
            this.loading.sending = true

            this.errors = updateUserRequest(data)

            if (Object.keys(this.errors).length > 0) {
                this.loading.sending = false
                return 0;
            }

            const res = await UserService.updateUser(this.user.id, data)

            try {
                if (res.data.status) {
                    this.hideUserModal('edit')
                    await this.fetchUser(this.user.id)
                }
            } catch (e) {
                //
            }

            this.loading.sending = false
        },

        showUserModal(name) {
            this.modal[name] = true
        },

        hideUserModal(name) {
            this.modal[name] = false
        },

        toggleUserModal(name) {
            this.modal[name] = !this.modal[name]
        },
    },

})
