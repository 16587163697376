import PageHead from '@/components/_ui/PageHead'
import PageLoader from '@/components/_ui/PageLoader'
import Breadcrumbs from '@/components/_ui/Breadcrumbs'
import Dropdown from '@/components/_ui/Dropdown'

import Modal from '@/components/_ui/Modal'
import ModalMenu from '@/components/_ui/ModalMenu'

import Badge from '@/components/_ui/Badge'
import Button from '@/components/_ui/Button'

import Input from '@/components/_ui/Input'
import InputNumber from '@/components/_ui/InputNumber'
import Text from '@/components/_ui/Text'
import InputImage from '@/components/_ui/InputImage'
import InputFile from '@/components/_ui/InputFile'
import Checkbox from '@/components/_ui/Checkbox'
import Select from '@/components/_ui/Select'
import SelectGrouped from "@/components/_ui/SelectGrouped.vue";

import Date from '@/components/_ui/Date'
import ScreenLock from "@/components/_ui/ScreenLock.vue";

export default [
    PageHead,
    PageLoader,
    Breadcrumbs,
    Dropdown,

    Badge,
    Button,

    Modal,
    ModalMenu,
    ScreenLock,

    Input,
    InputNumber,
    Text,
    InputImage,
    InputFile,
    Checkbox,
    Select,
    SelectGrouped,

    Date,
]
