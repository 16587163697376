<template>
  <div class="group relative">

    <div @click="isOpen = !isOpen" class="dropdown flex justify-between space-x-2 cursor-pointer">
      <div class="p-5 flex justify-between space-x-3 relative">
        <mdicon :name="iconList[volume * 3 / 90 ]"/>
      </div>
    </div>

    <transition name="fade" appear>
      <ul v-show="isOpen"
          class="dropdown-menu w-40 absolute top-full right-0 rounded-md shadow-lg bg-white overflow-hidden z-10">
        <li class="p-2 hover:bg-gray-50">
          <label for="SetNotificationVolume" class="form-label">
            Громкость
          </label>
          <input id="SetNotificationVolume" type="range" v-model="volume" class="w-full cursor-pointer" min="0" max="90"
                 step="30">
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NavVolumeComponent',
  data() {
    return {
      isOpen: false,
      volume: 60,
      iconList: ['volume-off', 'volume-low', 'volume-medium', 'volume-high'],
    }
  }
}
</script>
