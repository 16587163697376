import ItemsView from "@/views/Item/ItemsView.vue";
import ItemsList from "@/views/Item/ItemsList.vue";
import ItemDetailsView from "@/views/Item/ItemDetailsView.vue";

export const itemRoutes = {
    path: '/items',
    component: ItemsView,
    name: 'Товари',
    icon: 'cube-outline',
    meta: {
        displayHeader: true,
        mainMenuLink: true,
        admin: true,
    },
    children: [
        {
            path: '',
            component: ItemsList,
        },
        {
            path: ':item',
            component: ItemDetailsView,
        },
    ],
}
